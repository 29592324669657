import Accordion from 'components/Accordion';
import RenderBarChart from 'components/BarChart';
import BarChartWrapper from 'components/BarChartWrapper';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react'
import { Read } from 'services/core';
import { FormSpacer } from 'ui/styled';
import { normalizeStrapiList } from 'utils';

const data = [
  { name: 'Jan', value: 80 },
  { name: 'Feb', value: 300 },
  { name: 'Mar', value: 500 },
  { name: 'Apr', value: 200 },
  { name: 'May', value: 350 },
  { name: 'Jun', value: 120 },
  { name: 'Jul', value: 1000 },
  { name: 'Aug', value: 600 },
  { name: 'Sep', value: 450 },
  { name: 'Oct', value: 300 },
  { name: 'Nov', value: 500 },
  { name: 'Dec', value: 250 },
];

const options = [ 
  { id:1, value: '12 months', filter:`filters[createdAt][$gte]=${ moment()?.subtract(12, 'months')?.format("YYYY-MM-DD") }` },
  { id:2, value: '6 months', filter:`filters[createdAt][$gte]=${ moment()?.subtract(6, 'months')?.format("YYYY-MM-DD") }` },
  { id:3, value: '30 days', filter:`filters[createdAt][$gte]=${ moment()?.subtract(30, 'days')?.format("YYYY-MM-DD") }` },
  { id:4, value: '7 days', filter:`filters[createdAt][$gte]=${ moment()?.subtract(7, 'days')?.format("YYYY-MM-DD") }` },
];

export default function Statistics({ profile, reload }) {

  const [activeOption, setActiveOption] = useState(null);
  const [activeFavoriteOption, setActiveFavoriteOption] = useState(null);
  const [activeWhatsappOption, setActiveWhatsappOption] = useState(null);

  const [infos, setInfos] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleOptionClick = (value) => {
    setActiveOption(value);
  };

  const handleFavoriteOptionClick = (value) => {
    setActiveFavoriteOption(value);
  }

  const handleWhatsappOptionClick = (value) => {
    setActiveWhatsappOption(value);
  }

  const whatsappData = useMemo(() => {
    return infos?.whatsapp?.map(m => ({ name:moment(m?.createdAt)?.format("MMM") }))
    ?.reduce((p, c) => p?.map(m => m?.name)?.includes(c?.name) ? p?.map(m => m?.name === c?.name ? { ...m, value: (m?.value + 1) } : m) : [...p, { ...c, value: 1 }] ,[])
  }, [infos])

  const favoriteData = useMemo(() => {
    return infos?.favorites?.map(m => ({ name:moment(m?.createdAt)?.format("MMM") }))
    ?.reduce((p, c) => p?.map(m => m?.name)?.includes(c?.name) ? p?.map(m => m?.name === c?.name ? { ...m, value: (m?.value + 1) } : m) : [...p, { ...c, value: 1 }] ,[])
  }, [infos])


  const init = async () => { 
    const lastTime = activeOption?.filter ? `${activeOption?.filter}&` : `` 
    const typeWhats = "filters[type][$eq]=whatsapp"

    setLoading(true)
 

    const favs = await Read(`likes?${ lastTime }filters[model][id][$eq]=${ profile?.id }`)
    const whatsActions = await Read(`actions?${ lastTime }${ typeWhats }`)
    
    const next = { 
      favorites: normalizeStrapiList(favs),
      whatsapp: normalizeStrapiList(whatsActions),
    } 
    
    setLoading(false)
    console.log({ next })
    setInfos(next)

  };

  useEffect(() => { init(); }, [activeOption]);

  return (
    <>
      {/* <Accordion title="Ad clicks" noBorder noPadding>
        <BarChartWrapper
          options={options}
          value="1752 clicks"
          activeOption={activeOption}
          onOptionClick={handleOptionClick}
        >
          <RenderBarChart data={data} height={273} />
        </BarChartWrapper>
      </Accordion>
      <FormSpacer /> */}
      <Accordion title="Added to favorites" noBorder noPadding>
        <BarChartWrapper
          options={options}
          value={`${infos?.favorites?.length} additions`}
          activeOption={activeOption}
          onOptionClick={handleOptionClick}
        >
          <RenderBarChart data={favoriteData} height={273} />h
        </BarChartWrapper>
      </Accordion>
      <FormSpacer />
      <Accordion title="Clicks on the Whatsapp button" noBorder noPadding>
        <BarChartWrapper
          options={options}
          value={`${infos?.whatsapp?.length} clicks`}
          activeOption={activeOption}
          onOptionClick={handleOptionClick}
        >
          <RenderBarChart data={whatsappData} height={273} />h
        </BarChartWrapper>
      </Accordion>
    </>
  )
}
