import React, { useContext } from 'react'
import Wrapper from '../Wrapper'
import Terms from 'components/Terms';

const privacyPolicySections = [
  {
    title: '1. Introduction',
    content: 'Our privacy page aims to inform our users about how we collect, use and protect their personal information while they use our platform. The privacy of our users is a priority for us, and this page details our privacy practices in compliance with applicable laws and regulations.'
  },
  {
    title: '2. Information Collected',
    content: 'In this section, we describe the types of personal information we collect from users and the methods by which this information is obtained. This includes, but is not limited to: Information provided by users during registration, such as name, email, date of birth, etc. Usage data such as browsing history, platform interactions, user preferences, etc. Information collected automatically through cookies and similar technologies.'
  },
  {
    title: '3. Use of Information',
    content: 'We explain how users\' personal information is used by our platform. This may include: Personalizing the user experience and delivering relevant content. Continuous improvement of our services and features. Communicating with users about updates, special offers, etc. Compliance with legal and regulatory obligations.'
  },
  {
    title: '4. Information Sharing',
    content: 'We detail who we share user information with and the reasons for such sharing. This may involve: Service partners who assist us in providing our services. Legal authorities, when required by law or in response to official requests. Third parties in the event of a merger, acquisition or sale of assets.'
  },
  {
    title: '5. Information Protection',
    content: 'We explain the security measures we implement to protect users\' information against unauthorized access, misuse, alteration or disclosure. This may include: Data encryption. Access controls. Regular monitoring of systems and networks.'
  },
  {
    title: '6. User Rights',
    content: 'We inform users of their rights in relation to their personal information, including the right to access, correct, delete or limit the use of their data. We also provide information on how users can exercise these rights.'
  },
  {
    title: '7. Changes to the Privacy Policy',
    content: 'We reserve the right to update or modify our privacy policy as necessary. Any significant changes will be communicated to users through our platform or by other appropriate means.'
  },
  {
    title: '8. Contact',
    content: 'We provide contact information so that users can contact us if they have any privacy-related questions, concerns, or requests.'
  },
  {
    title: '9. Effective Date',
    content: 'We indicate the effective date of our privacy policy and inform users of the importance of regularly reviewing this page to stay up to date with our privacy practices.'
  },
  {
    title: '10. Legal Compliance',
    content: 'We declare our commitment to compliance with applicable data protection laws and regulations and inform users of their rights and remedies in the event of privacy violations.'
  }
];

export default function ModalPrivacyPolicy() {

  return (
    <>
      <Wrapper>
        <Terms terms={privacyPolicySections} title={'Privacy Policy'} subtitle={"Carefully read the platform's terms of use before proceeding."} />
      </Wrapper>
    </>
  )
}