import React from 'react'
import { PaymentBody, PaymentContainer, PaymentText } from './styled'
import PaymentCard from 'components/Cards/PaymentCard'

export default function Payment({ action, noText, loading, active }) {
  return (
    <>
      <PaymentContainer>
        {noText ? null : <PaymentText>
          In addition to the periodic highlight included as part of the control panel functionalities, you will also have the option to purchase additional highlights. This will allow you to feature your ads more frequently or for longer periods of time, further increasing your exposure on the platform.
        </PaymentText>}
        <PaymentCard active={active} action={action} loading={loading} />
      </PaymentContainer>

    </>
  )
}
