import React, { useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';


import {
    RegisterCall,
    BodyContainer,
    Banner,
    BodyContent,
    FormContainer
} from './styled'

import ContainerUnauthenticated from "containers/Unauthenticated";

import { DoRegister } from "services/authentication";
import { exposeStrapiError, normalizeStrapiList } from "utils";
import { Background, FormSpacer, FormTitle, Title } from "ui/styled";
import { Container } from "reactstrap";

import Footer from "components/Footer";
import InfoData from "components/InfoData";

import RegisterForm from "components/RegisterForm";
import PlansCard from "components/Cards/PlansCard";
import Success from "components/Success";

import { isEmail } from "utils/validators";
import { Create, Read } from "services/core";

export default function RegisterCustomer() {

    const history = useHistory();
    const navigate = to => history.push(`/${to}`);

    const [plans, setPlans] = useState([])

    const [loading, setLoading] = useState(false)
    const [infoOption, setInfoOption] = useState('Personal data')
    const [success, setSuccess] = useState(null)

    const [form, setForm] = useState({}) 

    const registerFormItems = useMemo(() => {
        return [
            {
                ref: 'name',
                placeholder: 'Name',
                type: 'text',
                full: true,
                outline: true,
            },
            {
                ref: 'email',
                placeholder: 'Email',
                type: 'text',
                full: true,
                outline: true,
            },
            {
                password: true,
                ref: 'password',
                placeholder: 'Password',
                type: 'password',
                full: true,
                outline: true,
            },
            {
                ref: 'cpassword',
                placeholder: 'Confirm password',
                type: 'password',
                full: true,
                outline: true,
            },
        ]
    }, [])

    const data = [
        { title: 'Personal data' },
        { title: 'Plan' },
    ]

    const valid = (payload, array) => {
        for (let item of array) {
            if (item?.ref && !payload?.[item?.ref]) {
                toast.error("Fill all fields")
                return false;
            }
        }

        if(!isEmail(payload?.email)){
            toast.error("Invalid email")
            return false;
        }

        if(payload?.password !== payload?.password){
            toast.error("Password and confirmation not match")
            return false;
        }

        return true;
    };

    const action = (payload) => {
        if (!valid(payload, registerFormItems)) { return ;}
        setInfoOption('Plan')
        setForm(payload)
    }

    const save = async (plan_id) => {
        setLoading(true)

        const result = await DoRegister({
            ...form,
            username: form.email?.replace(/ /g, ''),
            email: form.email?.replace(/ /g, ''),
            confirmed: true,
            blocked: false,
            role: 1,
            plan: plan_id,
            status: true,
        })

        setLoading(false)
        if (result && !exposeStrapiError(result)) {
            await Create("welcome", { name:form?.name, email:form?.email })
            completeLogin()
        }
    }

    const completeLogin = () => {
        toast.success('Account successfully registred!');
        // navigate('login')
        handleSuccess()
    }

    const handleSuccess = () => {
        setSuccess({
            title: 'Registration completed successfully',
            text: "You must verify your email to activate your account. If you don't find it in your inbox, please check your spam folder.",
            icon: 'success',
        })
    }

    const init = async () => {
        const result = await Read("plans")
        if(result){
            const normalResult = normalizeStrapiList(result)
            setPlans(normalResult)
        }
    }

    useEffect(() => {
        init()
    }, [])

    return (
        <>
            <ContainerUnauthenticated background={success ? '/images/success.png' : ''} scrollTo={infoOption} >
                {!success ? null : <Success {...success} footer />}
                {success ? null : <>
                    <BodyContainer>
                        <Background />
                        <BodyContent>
                            <InfoData data={data} active={infoOption} />
                            {
                                infoOption === 'Plan' ? null :
                                    <>
                                        <Container>
                                            <FormTitle>Fill in your details</FormTitle>
                                            <Title nomargin>User registration</Title>
                                        </Container>
                                        <RegisterForm items={registerFormItems} action={action} />
                                    </>
                            }
                            {
                                infoOption !== 'Plan' ? null :
                                    <>
                                        <Container>
                                            <FormTitle>Plan</FormTitle>
                                            <Title nomargin>Select a plan</Title>
                                        </Container>
                                        <PlansCard item={plans?.find(f => f.title === 'Free')} loading={loading} action={() => save(plans?.find(f => f.title === 'Free')?.id)} /> 
                                    </>
                            }
                        </BodyContent>
                    </BodyContainer>
                    <Footer />
                </>}
            </ContainerUnauthenticated>
        </>
    );
}