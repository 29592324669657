import Button from 'components/Form/Button'
import UserInfo from 'components/UserInfo'
import React, { useMemo, useState } from 'react'
import { ProfileImgContainer, ProfileName, UserDetailContainer, UserInfoContainer } from './styled'
import { FormSpacer, Load, LoadCenter } from 'ui/styled'
import UserActions from 'components/UserActions'
import ProfileImgPreview from 'components/Profile/ProfileImgPreview'
import moment from 'moment'
import { exposeStrapiError, parseStrapiImage } from 'utils'
import { Update } from 'services/core'
import { toast } from 'react-toastify';
import { DoForgotPassword } from 'services/authentication'

export default function UserDetails({ back, register, loading, reload }) {
  const [isEditing, setIsEditing] = useState(false)

  const [saving, setSaving] = useState(false)
  const [form, setForm] = useState({  })
  const formValue = ref => { return form?.[ref] ? form?.[ref] : ''; }
  const changeForm = (value, ref) => { setForm({ ...form, [ref]: value }); }

  const editablefields = ["Name", "E-mail"]

  const useInfos = useMemo(() => {
    setForm({...register, Name: register?.name || "", ["E-mail"]:register?.email})
    return [
      { text: 'Name', value: register?.name, medium: true },
      { text: 'User since', value: moment(register?.createdAt)?.format("LL") },
      { text: 'Status', value: register?.status ? 'Active' : 'Inactive' },
      !register?.last ? null : { text: 'Last acess', value: moment(register?.last)?.format("LL") },
      { text: 'E-mail', value: register?.email },
      // { text: 'Profit for the platform', value: 'R$ 1.000,00' },
    ]?.filter(f => isEditing ? editablefields?.includes(f?.text) : f)
  }, [register, isEditing])

  const handleEditing = () => {
    setIsEditing(!isEditing)
  }

  const buttons = useMemo(() => [
    isEditing ? null : { label: 'Modify information', icon: 'pencil-small', action: handleEditing },
    !isEditing ? null : { label:  'Save', icon:  'save', action: () => save(), loadable: true },
    { label: 'Redefine password', icon: 'lock-white', action: () => reset() },
    { label: 'Suspend account', icon: 'trash-white', action: () => susp() },
    { label: 'Send email', icon: 'email-white', action: () => window.open(`mailto:${ register?.email }`) },

  ]?.filter(f => f), [register, isEditing, form])
  
  const save = async () => {
    const payload = {
      name: form?.Name,
      email: form?.['E-mail'],
    }
    setSaving(true)
    const result = await Update("users", { ...payload }, register?.id)
    setSaving(false)
    
    if(result && !exposeStrapiError(result)){
      toast.success('Updated Successfully'); 
      if(reload && typeof reload === 'function'){ reload() ;}
      handleEditing()
    }
  }

  const susp = async () => {
    const payload = {
      banned_at: new Date()?.toISOString()
    }
    setSaving(true)
    const result = await Update("users", { ...payload }, register?.id)
    setSaving(false)
    
    if(result && !exposeStrapiError(result)){
      toast.success('Suspended Successfully'); 
      if(reload && typeof reload === 'function'){ reload() ;} 
    }
  }
  
  const reset = async () => {
    const payload = {
      email: form?.['E-mail'],
    }
    setSaving(true)
    const result = await DoForgotPassword(payload)
    setSaving(false)
    
    if(result && !exposeStrapiError(result)){
      toast.success('Reset password email sent'); 
      if(reload && typeof reload === 'function'){ reload() ;} 
    }
    
  }

  return (
    <>
      <Button onClick={back} width={'fit-content'} nospace small leftIcon={'chevron-left'}>
        Back
      </Button>
      <FormSpacer />
      <UserDetailContainer>
        {
          loading ? null : 
          <ProfileImgContainer>
            <ProfileImgPreview medium source={ register?.image?.url ? parseStrapiImage(register?.image?.url) : null } />
          </ProfileImgContainer>
        }
        <UserInfoContainer>
          {
            loading ? <LoadCenter><Load /></LoadCenter> :
            useInfos.map((item, index) => (
              <UserInfo
                key={index}
                text={item.text}
                value={item.value}
                medium={item.medium}
                isEditing={isEditing}
                formValue={formValue}
                changeForm={changeForm}
              />
            ))
          }
        </UserInfoContainer>
        <UserActions buttons={buttons} loading={saving} />
      </UserDetailContainer>
    </>
  )
}
