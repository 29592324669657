import { HomeHeaderContainer } from 'components/Admin/Owner/Home/styled';
import DashboardCard from 'components/Cards/DashboardCard';
import React, { useMemo } from 'react'
import { parseStrapiImage } from 'utils';

export default function OwnerHeader({ noPayment, infos }) {

  const dashboardData = useMemo(() => {
    return [
      {
        title: 'New users',
        subtitle: 'Last 30 days',
        images: (infos?.users||[])?.map(m => ({
          src: m?.image?.url ? parseStrapiImage(m?.image?.url) : null
        }))?.filter(f => f.src)?.slice(0,3),
        legend: `${ (infos?.users||[])?.length } new users`,
        secondary: true,
      },
      {
        title: 'New escort',
        subtitle: 'Last 30 days',
        images: (infos?.models||[])?.map(m => ({
          src: m?.user?.image?.url ? parseStrapiImage(m?.user?.image?.url) : null
        }))?.filter(f => f.src)?.slice(0,3),
        legend: `${ (infos?.models||[])?.length } new models`,
        secondary: true,
      },
      {
        title: 'Visits to the website',
        subtitle: 'Last 30 days',
        value: `${ (infos?.visits||[])?.length }`,
        secondary: true,
      },
      noPayment ? null : {
        title: 'Payment methods',
        subtitle: 'Method used by your customers',
        value: 'stripe',
        secondary: true,
      },
    ].filter(f => f);
  }, [ infos ]);

  return (
    <>
      <HomeHeaderContainer >
        {dashboardData?.map((item, index) => (
          <DashboardCard
            three={noPayment}
            key={index}
            {...item}
          />
        ))}
      </HomeHeaderContainer>
    </>
  )
}
