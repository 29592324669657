import React from 'react'
import { AnnouncementDescription, AnnouncementFeatures, AnnouncementIconContainer, AnnouncementInfos, AnnouncementTitle, AnnouncementTopContainer, Banner } from './styled'
import { Icon } from 'ui/styled';

export default function AnnouncementSection() {
  const features = [
    {
      icon: "doll-black",
      title: "Custom Profile",
      description: "Create a unique and attractive profile that highlights your talents, services and personality."
    },
    {
      icon: "expand-visibility",
      title: "Expand your visibility",
      description: "Reach a wider audience and increase your business opportunities by being featured in our 'Featured Girls' section."
    },
    {
      icon: "ease-use",
      title: "Ease of Use",
      description: "Our intuitive and user-friendly platform makes managing your profile and interacting with potential clients simple."
    },
    {
      icon: "positive-reviews",
      title: "Positive Reviews",
      description: "Receive valuable feedback from satisfied customers and increase your reputation in the community."
    },
    {
      icon: "money-black",
      title: "Special Promotions",
      description: "Get exclusive access to special offers and promotional events to boost your business."
    }
  ];
  return (
    <>
      <AnnouncementTopContainer>
        <Banner />
        <AnnouncementInfos>
          {
            features.map((feature, index) => ((
              <>
                <AnnouncementFeatures key={index}>
                  <AnnouncementIconContainer>
                    <Icon icon={feature.icon} />
                  </AnnouncementIconContainer>
                  <AnnouncementTitle>
                    {feature.title}
                    <AnnouncementDescription>
                      {feature.description}
                    </AnnouncementDescription>
                  </AnnouncementTitle>
                </AnnouncementFeatures>
              </>
            )))
          }
        </AnnouncementInfos>
      </AnnouncementTopContainer>
    </>
  )
}
