import React, { useContext, useRef, useState } from 'react'
import { Title } from 'ui/styled'
import { CheckContainer, CheckLabel, CheckLink, FormContainer } from './styled'
import FormCore from '../Form/Core'
import Check from 'components/Form/Check'
import { CoreContext } from 'context/CoreContext'
import Button from 'components/Form/Button'
import { toast } from 'react-toastify'

export default function RegisterForm({ items, action, loading }) {
  const { setModal } = useContext(CoreContext)

  const formRef = useRef()

  const [active, setActive] = useState(false)

  const handleActive = () => {
    setActive(!active)
  }

  const next = async () => {
    if(!active){
      toast.error("Accept terms to continue")
      return;
    }

    if(action && typeof action === 'function'){
      const form = formRef?.current?.getForm()
      action(form)
    }
  }

  return (
    <>
      <FormContainer>
        <Title small>Personal data</Title>
        <FormCore ref={formRef} formItems={items} />
        <CheckContainer>
          <Check checked={active} onChange={handleActive} />
          <CheckLabel>
            I agree with the platform's <CheckLink link onClick={() => setModal({ type: 'privacy' })} >Privacy Policy</CheckLink> and <CheckLink onClick={() => setModal({ type: 'terms' })}>Terms of Service</CheckLink>.
          </CheckLabel>
        </CheckContainer>
        <Button outlineGradient between loading={loading} rightIcon={'chevron-right'} onClick={next}>Advance</Button>
      </FormContainer>
    </>
  )
}
