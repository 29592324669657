import PaymentCard from 'components/Cards/PaymentCard';
import MyCredits from 'components/MyCredits';
import React, { useContext, useMemo } from 'react';
import Button from 'components/Form/Button';
import { CardsContainer, CreditsContainer } from './styled';
import EscortPlansCard from 'components/Cards/EscortPlans';
import { CoreContext } from 'context/CoreContext';

export default function Plans() {
  const { setModal, user } = useContext(CoreContext);


  const plansItem = useMemo(() => [
    {
      icon: 'basic',
      title: 'Basic Plan',
      subtitle: 'Featured for 5 days',
      text: 'You can have your ad featured for 5 days.',
      value: '5 credits',
      price: '89,90',
      label: 'Choose plan',
      action: (item) => setModal({ type: 'purchasedetails', data: { ...item } }),
    },
    {
      icon: 'bronze',
      title: 'Bronze Plan',
      subtitle: 'Featured for 15 days',
      text: 'You can have your ad featured for 2 weeks.',
      value: '15 credits',
      price: '199,90',
      label: 'Choose plan',
      action: (item) => setModal({ type: 'purchasedetails', data: { ...item } }),
    },
    {
      icon: 'premium',
      title: 'Premium Plan',
      subtitle: 'Featured for 30 days',
      text: 'You can have your ad featured for 1 month.',
      value: '30 credits',
      price: '399,90',
      label: 'Choose plan',
      action: (item) => setModal({ type: 'purchasedetails', data: { ...item } }),
    },

  ], []);

  return (
    <>
      <CreditsContainer>
        <MyCredits noChoose
          title={'Your credits'}
          subtitle={'Your credits have expired. You need to buy more credits to be featured on the platform.'}
          value={`${ user?.wallet?.credits || "0" } credits`}
        />
        <CardsContainer>
          {
            plansItem?.map((item, index) => (
              <EscortPlansCard key={index} {...item} />
            ))
          }
        </CardsContainer>
      </CreditsContainer>
    </>
  );
}