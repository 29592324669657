import styled from "styled-components";

export const ReviewContainer = styled.div.attrs({
})`
  display: flex;
  gap: 32px;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
`;


