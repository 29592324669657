import { CoreContext } from 'context/CoreContext'
import React, { useContext, useState } from 'react'
import { FormText, Icon, Title } from 'ui/styled'
import { AgeVerification, ButtonContent, CloseContainer, CompanyButtonContainer, DeleteAccountContainer, Logo, ModalText, ModalTitle, PlanValue, PurchaseDetails, SubValue, TermContainer, Value } from './styled'
import Wrapper from '../Wrapper';
import Button from 'components/Form/Button';
import { Container } from 'reactstrap';
import { RemoveMe } from 'services/me';
import { toast } from 'react-toastify';
import { DoLogout } from 'services/authentication';
import { useHistory } from 'react-router-dom';

export default function ModalDeleteAccounts() {

  const history = useHistory(); 
  const navigate = to => history.push(`/${ to }`); 

  const { modal,setTracker, setModal, setUser } = useContext(CoreContext)

  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)


  const close = () => {
    setModal(null)
  }
 

  const exit = async () => {  
    await DoLogout()  
        setUser(null)
        setTracker(null)
    navigate('login');
  }  

  const deleteAccount = async () => { 
      setLoading(true)
      await RemoveMe();
      toast.error('Account deleted successfully')
      exit();
      close()
      setLoading(false)  
  }

  return (
    <>
      <Wrapper background={'/images/success.png'}>
        <DeleteAccountContainer>
          <Logo icon={"logo"} />
          {
            success ? null : <>
              <ModalTitle>
                You are about to permanently delete your account.
              </ModalTitle>
              <ModalText>
                If you have decided not to delete your account at this time, you can simply close this page and continue using our platform as usual. If you have questions or need assistance, don't hesitate to contact our support team. We are here to help!
              </ModalText>
              <ButtonContent>
                <Button loading={loading} onClick={deleteAccount} small outline color={'info'} width={'fit-content'} leftIcon={'trash'}>
                  Confirm account deletion
                </Button>
                <Button onClick={close} small color={'lightBlue'} width={'fit-content'} leftIcon={'trash-white'}>
                  I don't want to delete
                </Button>
              </ButtonContent>
            </>
          }
          {
            !success ? null : <>
              <ModalTitle>
                Your account has been successfully deleted.
              </ModalTitle>
              <ModalText>
                It was a pleasure having you spend this time with us. Remember that you are welcome whenever you need us. We will be here to help you.
              </ModalText>
            </>}
        </DeleteAccountContainer>
      </Wrapper >
    </>
  )
}
