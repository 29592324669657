import React, { useContext, useEffect } from "react";

import {
    DashboardAnimation,
    DashboardTitle,
    DashboardText,
    BodyContent,
    HomeTextInfo,
    BodyContainer,
    BodyRegister,
    BodyRegisterTitle,
    HomeBody,
    Banner,
    HomeText
} from "./styled";

import ContainerAuthenticated from "containers/Authenticated";
import { Row, Col } from "reactstrap";
import Button from "components/Form/Button";
import { CoreContext } from "context/CoreContext";
import HomeFilters from "components/HomeFilters";
import Escorts from "components/Escorts";
import Footer from "components/Footer";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ModalPrivacyPolicy from "components/Modal/Privacy";

export default function DashboardHome() {

    const history = useHistory();
    const navigate = to => history.push(`/${to}`);

    return (
        <>
            <ContainerAuthenticated free>
                <BodyContainer>
                    <Banner />
                    <BodyContent>
                        <HomeTextInfo>
                            Dexsire is respect, <br /> safety and dignity
                        </HomeTextInfo>
                        <HomeBody>
                            <BodyRegister>
                                <BodyRegisterTitle>
                                    Register right now.
                                </BodyRegisterTitle>
                                <Button width={'162px'} onClick={() => navigate('pre-login')}>
                                    Register
                                </Button>
                            </BodyRegister>
                            <HomeFilters />
                        </HomeBody> 
                        <HomeText>
                            Our escorts
                        </HomeText> 
                    </BodyContent>
                </BodyContainer>
                <Escorts />
                <Footer />
            </ContainerAuthenticated>
        </>
    );
}