import ContainerAuthenticated from 'containers/Authenticated'
import React, { useEffect, useMemo, useState } from 'react'
import { AnnouncementContainer, AnnouncementTop, FaqContent, TitleContent } from './styled'
import { FormSpacer, FormTitle, Load, LoadCenter, Title } from 'ui/styled'
import Footer from 'components/Footer'

import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import FaqAccordion from 'components/FaqAccordion'
import Button from 'components/Form/Button'
import { Read } from 'services/core'
import { normalizeStrapiList } from 'utils'

export default function Faq() {
  const history = useHistory();
  const navigate = to => history.push(`/${to}`);

  const [faqs, setFaqs] = useState([]);
  const [loading, setLoading] = useState(false);

  const init = async () => {
    setLoading(true)
      const result = await Read("faqs")
      const normalResult = normalizeStrapiList(result)
      setFaqs(normalResult)
    setLoading(false)
  }

  useEffect(() => { init() ;}, [])

  return (
    <>
      <ContainerAuthenticated free>
        <AnnouncementTop>
          <FormSpacer />
          <Title>
            Frequently asked questions
          </Title>
        </AnnouncementTop>
        <AnnouncementContainer>
          <TitleContent>
            <Title small>
              Frequently asked questions from users about using the application, its features and policies.
            </Title>
          </TitleContent>
          <FaqContent>
            {
              loading ? <LoadCenter><Load /></LoadCenter> :
              faqs.map((faq, index) => (
                <FaqAccordion key={index} question={faq?.ask} answer={faq?.answer} />
              ))
            }
          </FaqContent>
          <FormSpacer large />
          <Title small>
            Did not find what you were looking for? <br /> Contact us.
          </Title>
          <Button onClick={() => navigate('contact')} outlineGradient width={'244px'} rightIcon={'chevron-right'}>
            Send Message
          </Button>
        </AnnouncementContainer>
        <Footer />
      </ContainerAuthenticated>
    </>
  )
}
