import React, { useContext } from 'react'
import { SuccessContainer, SuccessContent, SuccessText } from './styled'
import { CoreContext } from 'context/CoreContext'

export default function SuccessFooter() {
  const { setModal } = useContext(CoreContext)
  return (
    <>
      <SuccessContent>
        <SuccessContainer>
          <SuccessText link onClick={() => setModal({ type: 'privacy' })}>
            Privacy Policy
          </SuccessText>
          <SuccessText link onClick={() => setModal({ type: 'terms' })}>
            Service Terms
          </SuccessText>
          <SuccessText>
            © Copyright 2024 Dexsire, Switzerland. All rights reserved.
          </SuccessText>
        </SuccessContainer>
      </SuccessContent>
    </>
  )
}
