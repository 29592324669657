import React from 'react'
import { BodyCard, CardTitle, LoginCardContainer, LoginImage } from './styled'
import { Icon } from 'ui/styled'
import Button from 'components/Form/Button'

export default function LoginCard({ title, img, registrationAction, loginAction }) {
  return (
    <>
      <LoginCardContainer>
        <LoginImage src={img} />
        <BodyCard>
          <CardTitle>{title}</CardTitle>
          <Button onClick={loginAction} outlineGradient>
            I already have registration
          </Button>
          <Button onClick={registrationAction} outlineGradient>
            I want to sign up
          </Button>
        </BodyCard>
      </LoginCardContainer>
    </>
  )
}
