import Button from 'components/Form/Button'
import React from 'react'
import { ButtonsContainer, UserActionsContainer } from './styled'
import { FormTitle, Load, LoadCenter } from 'ui/styled'

export default function UserActions({ buttons, loading }) {
  return (
    <>
      <UserActionsContainer>
        <FormTitle white >Admin actions</FormTitle>
        <ButtonsContainer>
          {
            loading ? <LoadCenter> <Load /> </LoadCenter> : <>
                {buttons.map((button, index) => (
                  <Button
                    key={index}
                    leftIcon={button.icon}
                    onClick={button.action}
                    small
                    color={'lightBlue'}
                    start
                    nospace
                  >
                    {button.label}
                  </Button >
                ))}
            </>
          }
        </ButtonsContainer>
      </UserActionsContainer>
    </>
  )
}
