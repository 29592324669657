import React, { useContext } from 'react'

import Wrapper from '../Wrapper'
import Terms from 'components/Terms';

const terms = [
  {
    title: '1. Introduction',
    content: 'The Terms of Service page is an essential part of our platform, defining the terms and conditions that govern users\' use of our services. This page establishes the rights and responsibilities of both users and the company, ensuring a safe and transparent experience for everyone involved.'
  },
  {
    title: '2. Acceptance of Terms',
    content: 'We explain that the use of our platform implies automatic and binding acceptance of the Terms of Service.'
  },
  {
    title: '3. Description of Services',
    content: 'We detail the services offered by our platform, including their functionalities, characteristics, and limitations. This gives users a clear understanding of what they can expect when using our services.'
  },
  {
    title: '4. User Responsibilities',
    content: 'We clarify users\' responsibilities when using our platform, including: providing accurate and up-to-date information during registration, respecting intellectual property rights, maintaining the security of access credentials, and not violating applicable laws or the rights of third parties.'
  },
  {
    title: '5. Intellectual Property',
    content: 'We inform users about the ownership of copyrights, trademarks, and other intellectual property rights related to our platform and its content. Users agree to respect these rights and not use our content in an unauthorized manner.'
  },
  {
    title: '6. Privacy Policy',
    content: 'We reference our privacy policy and explain how we treat user information. Users are encouraged to review our privacy policy to understand how their information is collected, used, and protected.'
  },
  {
    title: '7. Modifications to the Terms of Service',
    content: 'We reserve the right to modify or update the Terms of Service as necessary. Changes become effective upon publication of the revised Terms on our platform.'
  },
  {
    title: '8. Limitation of Liability',
    content: 'We exempt the company from liability for direct, indirect, incidental, consequential, or punitive damages resulting from the use of our services. Users agree to use our services at their own risk.'
  },
  {
    title: '9. Termination of Service',
    content: 'We reserve the right to terminate or suspend users\' access to our platform, without prior notice, in case of violation of the Terms of Service.'
  },
  {
    title: '10. General Provisions',
    content: 'We include provisions on applicable law, jurisdiction, waiver of rights, contract integrity, and other general provisions to ensure the enforceability and effectiveness of the Terms of Service.'
  },
  {
    title: '11. Contact',
    content: 'We provide contact information so that users can contact us with any questions, concerns, or issues regarding the Terms of Service.'
  }
];

export default function ModalTerms() {

  return (
    <>
      <Wrapper>
        <Terms terms={terms} title={'Terms of service'} subtitle={"Carefully read the platform's terms of service before proceeding."} />
      </Wrapper>
    </>
  )
}