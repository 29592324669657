import React, { act, useContext, useMemo } from 'react'
import { FooterContainer, FooterContent, FooterIcon, FooterItem, FooterSectionOptions, FooterSectionOptionsContact, FooterText, SubTitle } from './styled'
import { FormSpacer, Icon } from 'ui/styled'
import { CoreContext } from 'context/CoreContext'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Button from 'components/Form/Button';

export default function SupportFooter() {
  const history = useHistory();
  const navigate = to => history.push(`/${to}`);

  const { setModal, contactUs } = useContext(CoreContext)

  const footerContact = useMemo(() => [
    !contactUs?.email ? null : { title: 'E-mail', icon: 'email-black', subTitle: contactUs?.email },
    !contactUs?.phone ? null : { title: 'Telephone', icon: 'phone-black', subTitle: contactUs?.phone },
  ].filter(f => f), [])

  const footerItems = [
    {
      title: 'Frequently asked questions',
      icon: 'question',
      subTitle: 'We have separated some questions that are requested here.',
      label: 'Access FAQ',
      action: () => navigate('faq')
    },
    {
      title: 'Privacy policy',
      icon: 'term',
      subTitle: 'Understand a little more about privacy policies.',
      label: 'Access',
      action: () => setModal({ type: 'privacy' })
    },
    {
      title: 'Service Terms',
      icon: 'term',
      subTitle: 'Understand a little more about the terms of service.',
      label: 'Access',
      action: () => setModal({ type: 'terms' })
    },
  ]
  return (
    <>
      <FooterContainer>
        <FooterSectionOptionsContact>
          {
            footerContact.map((option, index) => (
              <FooterContent key={index}>
                <FooterIcon>
                  <Icon icon={option.icon} />
                </FooterIcon>
                <FooterText>
                  {option?.title}
                  <SubTitle>{option?.subTitle}</SubTitle>
                </FooterText>
              </FooterContent>
            ))
          }
        </FooterSectionOptionsContact>
        {
          footerItems.map((option, index) => (
            <FooterItem>
              <FooterIcon>
                <Icon icon={option?.icon} />
              </FooterIcon>
              <FooterText >
                {option?.title}
                <SubTitle>{option?.subTitle}</SubTitle>
              </FooterText>
              <Button color={'borderBackground'} width={'fit-content'} nospace onClick={option?.action} small rightIcon={'chevron-right'}>
                {option?.label}
              </Button>
            </FooterItem>
          ))
        }
      </FooterContainer>
    </>
  )
}
