import React from 'react'
import { FormBorder, PaymentBody, PaymentBorder, PaymentText, PaymentTitleContainer } from './styled'
import { Title } from 'ui/styled'
import Button from 'components/Form/Button'

export default function PaymentCard({ action, loading, active }) {
  return (
    <>
      <PaymentBorder>
        <PaymentBody>
          {
            active ? 
              <PaymentTitleContainer>
                <PaymentText>
                  { active?.license } - License
                </PaymentText>
                <Title nomargin>
                { active?.value }
                </Title>
              </PaymentTitleContainer>
               : 
              <PaymentTitleContainer>
                <PaymentText>
                  5 days - License
                </PaymentText>
                <Title nomargin>
                  89.90 francs
                </Title>
              </PaymentTitleContainer>
          }
          <FormBorder />
          <Title nomargin>
            stripe
          </Title>
          <PaymentText>
            Payment for your subscription will be made via Stripe.
          </PaymentText>
          <Button between loading={loading} rightIcon={"chevron-right"} outlineGradient width={"202px"} nospace onClick={active?.action ? active?.action : action}>
            Continue
          </Button>
        </PaymentBody>
      </PaymentBorder>
    </>
  )
}
