import styled from "styled-components";

export const ReportsContainer = styled.div.attrs({})`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

export const BarChartContainer = styled.div.attrs({})`
  display: flex;
  gap: 16px;
  width: 100%;
  
`;

export const HomeHeaderContainer = styled.div.attrs({})`
  display: flex;
  gap: 16px;
  flex-direction: column;
  max-width: 309px;
`;

export const IconContainer = styled.div.attrs({})`
  display: flex;
  gap: 16px;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
  cursor: pointer;
`;