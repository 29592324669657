import React, { useState } from 'react'
import { ButtonContainer, Icon, Title } from 'ui/styled'
import { Image, ImageContainer, ImagesGalleryContainer, PlayButton } from './styled'
import Button from 'components/Form/Button'
import ImageView from 'components/ImageView';
import { parseStrapiImage } from 'utils';

export default function ImagesGallery({ images, videos, noTitle, small, noMore, profile }) {
  const [currentIndex, setCurrentIndex] = useState(null);


  const content = videos && videos.length > 0 ? videos : images;

  const openImage = (index) => {
    setCurrentIndex(index);
  };

  const closeImage = () => {
    setCurrentIndex(null);
  };

  const showNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % content.length);
  };

  const showPrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + content.length) % content.length);
  };


  return (
    <>
      {noTitle ? null : <Title small>
        {videos && videos.length > 0 ? 'Video Gallery' : 'Photo Gallery'}
      </Title>}
      <ImagesGalleryContainer >
        {
          content?.map((image, index) => (
            <ImageContainer key={index} onClick={() => openImage(index)} isVideo={(videos && videos.length > 0)} small={small}>
              {
                videos?.length ? 
                <video autoPlay={false} muted={true} loop={true} width="80%" style={{ aspectRatio:'4 / 3'}} controls>
                  <source src={ (image?.src) } type="video/mp4" />
                  <source src={ (image?.src) } type="video/mov" />
                  <source src={ (image?.src) } type="video/avi" />
                  <source src={ (image?.src) } type="video/quicktime" />
                  <source src={ (image?.src) } type="video/ogg" />
                </video>
                 : 
                <Image src={image.url} alt={image.alt} />
              }
            </ImageContainer>
          ))
        }
      </ImagesGalleryContainer>
      {noMore ? null : <ButtonContainer center>
        <Button width={'179px'} outlineGradient>
          Load more
        </Button>
      </ButtonContainer>}
      {currentIndex !== null && (
        <ImageView
          profile={profile}
          presrc={content?.[currentIndex-1]?.src || content?.[currentIndex-1]?.url}
          src={content?.[currentIndex]?.src || content?.[currentIndex]?.url}
          possrc={content?.[currentIndex+1]?.src || content?.[currentIndex+1]?.url}
          type={content[currentIndex].type || 'image'}
          showNext={showNext} showPrev={showPrev} closeImage={closeImage}
        />
      )}
    </>
  );
}