import React, { useState } from 'react';

import UploadFile from 'components/Form/UploadFile';
import { AppearanceContainer, AppearanceText, AppearanceTitle, AppearanceTitleContainer, UploadFileContainer } from './styled';
import { Icon } from 'ui/styled';
import { Container } from 'reactstrap';
import { toast } from 'react-toastify';
import UploadAndPreview from 'components/UploadAndPreview';
import { parseStrapiImage } from 'utils';

export default function Appearance({ uploadedFile, setUploadedFile }) {
  const [preview, setPreview] = useState(null);
  // const [uploadedFile, setUploadedFile] = useState(null); 

  const handlePreview = (url) => {
    setPreview(url);
  };

  const handleFileChange = (file) => {
    setUploadedFile(file);
  };

  const validateFile = (file) => { 
    if (file.size > 50000000) {
      toast.error("The file is too large. The maximum size is 50MB.");
      return false;
    }
    return true;
  };

  // console.log("uploadedFile", uploadedFile)

  return (
    <>
      <AppearanceContainer>
        <AppearanceTitleContainer>
          <AppearanceTitle>Upload a 360º video</AppearanceTitle>
          <AppearanceText>
            <Icon icon="doubt" />
            Instructions on how to record 360º video</AppearanceText>
        </AppearanceTitleContainer>
        <UploadFile
          onChange={handleFileChange}
          onPreview={handlePreview} 
          validate={validateFile}
        >

          <UploadFileContainer>
          {
            preview ? 
              <video autoPlay={true} muted={true} loop={true} width="80%" style={{ aspectRatio:'4 / 2'}} controls>
                <source src={ parseStrapiImage(uploadedFile?.url) } type="video/mp4" />
                <source src={ parseStrapiImage(uploadedFile?.url) } type="video/mov" />
                <source src={ parseStrapiImage(uploadedFile?.url) } type="video/avi" />
                <source src={ parseStrapiImage(uploadedFile?.url) } type="video/quicktime" />
                <source src={ parseStrapiImage(uploadedFile?.url) } type="video/ogg" />
              </video>
            : <>
                <Container />
                <Icon icon="double-page" />
                <AppearanceText>Drag the video here or click here</AppearanceText>
            </>
          }
          </UploadFileContainer>

        </UploadFile>
        <AppearanceTitleContainer>
          <AppearanceText>Supported files: MP4 or AVI</AppearanceText>
          <AppearanceText>Maximum file size: 50mb</AppearanceText>
        </AppearanceTitleContainer>
      </AppearanceContainer>

    </>
  );
};
