import React, { useContext, useMemo, useState } from "react";
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
    FormTitle,
    FormText,
    FormSpacer,
    ForgotLink,
    LoginContainer,
    LoginContent,
} from './styled'

import Button from "components/Form/Button";
import Input from 'components/Form/Input'

import ContainerUnauthenticated from "containers/Unauthenticated";
import { CoreContext } from "context/CoreContext";
import { DoLogin } from "services/authentication";
import { exposeStrapiError } from "utils"; 
import { Container } from "reactstrap";

export default function Login() {
    const history = useHistory();
    const navigate = to => history.push(`/${to}`);

    const { reloadMe } = useContext(CoreContext)
    const [ loading, setLoading ] = useState(false)
    
    const [form, setForm] = useState({})
    const formValue = ref => { return form?.[ref] ? form?.[ref] : ''; }
    const changeForm = (value, ref) => { setForm({ ...form, [ref]: value }); }

    const valid = (verbose = false) => {

        if (!formValue('identifier') || !formValue('identifier').length) {
            if (verbose) { toast.error('Preencha o campo: Email'); }
            return false;
        }

        if (!formValue('password') || !formValue('password').length) {
            if (verbose) { toast.error('Preencha o campo: Senha'); }
            return false;
        }

        return true
    }

    const login = async () => {
        if (!valid(true)) { return; }
        setLoading(true)
        const result = await DoLogin({ ...form, identifier: form.identifier?.replace(/ /g, '') })
        setLoading(false)
        if (result && !exposeStrapiError(result)) {
            completeLogin(result)
            navigate('')
        }
    }

    const completeLogin = (result) => {
        if (result?.user) { 
            reloadMe()
         }
        navigate('')
    } 

    return (
        <>
            <ContainerUnauthenticated background={ '/images/backgroundLogin.png' } login>
                <LoginContent>  
                    <LoginContainer>
                        <Container>
                            <FormTitle>Login</FormTitle>
                            <FormText>Enter your username and password.</FormText>
                        </Container>
                        <FormSpacer />
                        <Input placeholder="E-mail" noHolder value={formValue('identifier')} onChange={e => changeForm(e.target.value, 'identifier')} startIcon={"letter"} />
                        <Input placeholder="Senha" type="password" noHolder value={formValue('password')} onChange={e => changeForm(e.target.value, 'password')} onSubmitEditing={login} startIcon={"lock-medium"} />
                        <ForgotLink onClick={() => navigate('forgot')}>I forgot my password</ForgotLink>
                        <Button nospace primary loading={loading} outlineGradient width={'161px'} onClick={login}>To enter</Button>
                    </LoginContainer> 
                </LoginContent>
            </ContainerUnauthenticated>
        </>
    );
}