import React, { useEffect, useMemo, useState } from 'react'
import { ButtonEditing, CheckContainer, FormContainer, InputContainer, NoteContainer, NoteText, ServiceHeaderContent, ServicesContainer } from './styled';
import { FormSpacer, Icon, Title } from 'ui/styled';
import Check from 'components/Form/Check';
import Select from 'components/Form/Select';
import Input, { MaskedInput } from 'components/Form/Input';
import { optionsBoobs, optionsCategory, optionsEyes, optionsHair, optionsLanguage, optionsMobility, optionsOrigin, optionsPayment, optionsSize, optionsWeight } from 'utils/options';
import Rating from 'components/Form/Rating';

export default function ServicesOffered({ note, editing, noteEditing, options, active, setActive, ethnicity, setEthnicity, aboutme, setAboutme, superForm, sobs, saveNote, saveServices, registering, profile, subActive, setSubActive, superLang }) {

  const [isEditing, setIsEditing] = useState(false)
  const [isNoteEditing, setIsNoteEditing] = useState(false)

  const [form, setForm] = useState({})
  const formValue = ref => { return form?.[ref] ? form?.[ref] : ''; }
  const changeForm = (value, ref) => { setForm({ ...form, [ref]: value }); }

  const [lang, setLang] = useState({})
  const langValue = ref => { return lang?.[ref] ? lang?.[ref] : ''; }
  const changeLang = (value, ref) => { setLang({ ...lang, [ref]: value }); }

  const handleActive = (item) => {
    if(!setActive || typeof setActive !== 'function') return ;
    if (active?.map(m => m?.id)?.includes(item?.id)) {
      setActive(prev => prev.filter(f => f?.id !== item?.id))
      return;
    }
    setActive(prev => [...prev, item])
  }
  
  const handleSubActive = (item) => {
    if(!setSubActive || typeof setSubActive !== 'function') return ;
    if (subActive?.map(m => m?.id)?.includes(item?.id)) {
      setSubActive(prev => prev.filter(f => f?.id !== item?.id))
      return;
    }
    setSubActive(prev => [...prev, item])
  }

  const handleEdit = () => {
    if(isEditing){
      if(saveServices && typeof saveServices === 'function'){ saveServices() ;}
    }
    setIsEditing(prev => !prev)
  }

  const handleNoteEdit = () => {
    if(isNoteEditing){
      if(saveNote && typeof saveNote === 'function'){ saveNote() ;}
    }
    setIsNoteEditing(prev => !prev)
    if(!isNoteEditing){
      setTimeout(() => { document.getElementById("note").focus() }, 100)
    }
  }

  useEffect(() => {
    if(superForm && typeof superForm === 'function'){ superForm(form) ;}
  }, [form])
  
  useEffect(() => {
    if(superLang && typeof superLang === 'function'){ superLang(lang) ;}
  }, [lang])


  useEffect(() => { if(sobs){ setForm({ ...form, service_observations: sobs }) }; }, [sobs])

  return (
    <>
      <ServicesContainer>

        {note || !profile ? null : <FormContainer>
          <InputContainer>

            <Input noHolder outline placeholder="Pseudo" value={formValue('pseudo')} onChange={e => changeForm(e.target.value, 'pseudo')} />

            <Select formed borderBackground placeholder='Category' options={optionsCategory} onChange={e => changeForm(e, 'category')} value={formValue('category')} />
            <Input spaced noHolder type='textarea' textarea outline placeholder="Bio" value={formValue('about_me')} onChange={e => changeForm(e.target.value, 'about_me')} />
            {/* <Select formed borderBackground placeholder='Age' options={[]} onChange={e => changeForm(e.target.value, 'age')} value={formValue('age')} /> */}
            <Input type='number' noHolder outline placeholder="Age" value={formValue('age')} onChange={e => changeForm(e.target.value, 'age')} />
            <MaskedInput mask={"+41 99 999 9999"} noHolder outline placeholder="Phone" value={formValue('phone')} onChange={e => changeForm(e.target.value, 'phone')} />

            <Select formed borderBackground placeholder='Canton' options={options?.regions || []} onChange={setEthnicity} value={ethnicity} />
            <Select formed borderBackground placeholder="Ville" options={(options?.cities || [])?.filter(m => `${m?.region?.data?.id}` === `${ethnicity}`) } value={formValue('city')} onChange={value => changeForm(value, 'city')} />
            
            <Select formed borderBackground placeholder='Nationality' options={optionsOrigin} onChange={e => changeForm(e, 'nationality')} value={formValue('nationality')} />
            
            <Select formed borderBackground placeholder='Size' options={optionsSize} onChange={e => changeForm(e, 'height')} value={formValue('height')} />
            <Select formed borderBackground placeholder='Weight' options={optionsWeight} onChange={e => changeForm(e, 'weight')} value={formValue('weight')} />
            <Select formed borderBackground placeholder='Hair color' options={optionsHair} onChange={e => changeForm(e, 'hair')} value={formValue('hair')} />
            <Select formed borderBackground placeholder='Breasts' options={optionsBoobs} onChange={e => changeForm(e, 'breasts')} value={formValue('breasts')} />
            <Select formed borderBackground placeholder='Eye color' options={optionsEyes} onChange={e => changeForm(e, 'eyes')} value={formValue('eyes')} />


              <Title verysmall> Mobility </Title>
              <CheckContainer>
                {
                  (optionsMobility||[])?.map((m, k) => (
                    <Check label={m?.title} key={k} disabled={!isEditing && !registering} checked={active?.map(mm => mm?.id)?.includes(m?.id) || m?.active} onChange={() => handleActive(m)} />
                  ))
                }
              </CheckContainer>

              <Title verysmall> Payments accepted </Title>
              <CheckContainer>
                {
                  (optionsPayment||[])?.map((m, k) => (
                    <Check label={m?.title} key={k} disabled={!isEditing && !registering} checked={subActive?.map(mm => mm?.id)?.includes(m?.id) || m?.active} onChange={() => handleSubActive(m)} />
                  ))
                }
              </CheckContainer>


              <Title verysmall> Languages spoken </Title> 
                {
                  (optionsLanguage||[])?.map((m, k) => (
                    <Rating image={m?.image} label={m?.title} key={k} disabled={!isEditing && !registering} value={langValue(m?.id)} onChange={e => changeLang(e, m?.id)}  />
                  ))
                } 

              {/* <Input type='date' outline placeholder="Birthdate" value={formValue('birthdate')} onChange={e => changeForm(e.target.value, 'birthdate')} />
              <MaskedInput mask={"+41 99 999 9999"} noHolder outline placeholder="Whatsapp" value={formValue('whatsapp')} onChange={e => changeForm(e.target.value, 'whatsapp')} />
              <MaskedInput mask={"+41 99 999 9999"} noHolder outline placeholder="Telegram" value={formValue('telegram')} onChange={e => changeForm(e.target.value, 'telegram')} /> */}
              
          </InputContainer>
        </FormContainer>
        }

        {
          profile ? null : <>
              <ServiceHeaderContent between={editing}>
                <Title small>
                  What services do you offer?
                </Title>
                {editing ?
                  <ButtonEditing onClick={handleEdit}> 
                    <Icon icon={isEditing ? 'save' : 'pencil'} />
                  </ButtonEditing>
                  : null}
              </ServiceHeaderContent>
              <CheckContainer>
                {
                  (options?.services||[])?.map((m, k) => (
                    <Check label={m?.title} key={k} disabled={!isEditing && !registering} checked={active?.map(mm => mm?.id)?.includes(m?.id) || m?.active} onChange={() => handleActive(m)} />
                  ))
                }
              </CheckContainer>
              {note ? null : <FormContainer>
              <FormSpacer />
                <InputContainer>
                  {/* <Select borderBackground placeholder='Canton' options={options?.regions || []} onChange={setEthnicity} value={ethnicity} />
                  <Select borderBackground placeholder="Ville" options={(options?.cities || [])?.filter(m => `${m?.region?.data?.id}` === `${ethnicity}`) } value={formValue('city')} onChange={value => changeForm(value, 'city')} /> */}
                  <Input noHolder outline placeholder="Describe yourself below:" type='textarea' textarea value={aboutme} onChange={e => setAboutme(e.target.value)} />
                </InputContainer>
              </FormContainer>
              }
              {
                !note ? null : <>
                  <NoteContainer noteEditing={noteEditing}>
                    <NoteText>
                      <Input id={"note"} noHolder disabled={!isNoteEditing} outline placeholder="Note:" type='textarea' textarea  value={formValue('service_observations')} onChange={e => changeForm(e.target.value, 'service_observations')} />
                    </NoteText>
                    {noteEditing ?
                      <ButtonEditing onClick={handleNoteEdit}>
                        <Icon icon={isNoteEditing ? 'save' : 'pencil'} />
                      </ButtonEditing>
                      : null}
                  </NoteContainer>
                </>
              }
          </>
        }
        </ServicesContainer>

    </>
  )
}
