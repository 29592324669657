import React, { useContext } from 'react'
import { DeleteAccountContainer, DeleteAccountText, DeleteAccountTitle } from './styled'
import Button from 'components/Form/Button';
import { CoreContext } from 'context/CoreContext';

export default function DeleteAccounts() {
  const { setModal } = useContext(CoreContext);

  const deleteText = `
  If you have decided not to continue using our platform, we offer the option to permanently delete your account. This process is irreversible and will result in the complete removal of all information associated with your account, including your profile, activity history and personal data.

  Please be aware that when you delete your account, you will lose access to all features and services offered by the platform. Additionally, any previously completed transactions, messages, or interactions will be permanently deleted and cannot be recovered.

  If you are sure you want to proceed with deleting your account, please confirm your decision below. Once confirmed, your account will be permanently deleted and you will be automatically logged out of the platform.
`;
  const formattedText = deleteText.split('\n').map((str, index) => (
    <React.Fragment key={index}>
      {str}
      <br />
    </React.Fragment>
  ));

  return (
    <>
      <DeleteAccountContainer>
        <DeleteAccountTitle>
          What do you need to know before deleting your account?
        </DeleteAccountTitle>
        <DeleteAccountText>
          {formattedText}
        </DeleteAccountText>
        <Button outline small width={'fit-content'} color={'info'} white leftIcon={'trash'} onClick={() => setModal({ type: 'deleteaccount' })}>Delete my account</Button>
      </DeleteAccountContainer>
    </>
  )
}
