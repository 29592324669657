import React from 'react'
import { Container, MyCreditsContainer, MyCreditsContent, Subtitle, Value } from './styled'
import { Icon, Title } from 'ui/styled'

import Button from 'components/Form/Button'
import { CHECKOUT_ENDPOINT } from 'services/api'

export default function MyCredits({ noChoose, title, subtitle, value, noButton }) {
  return (
    <>
      <MyCreditsContainer>
        <MyCreditsContent>
          <Icon icon="coins" width={24} nomargin />
          <Title small left nomargin>{title}</Title>
          <Subtitle >{subtitle}</Subtitle>
        </MyCreditsContent>
        <MyCreditsContent row>
          <Value>{value}</Value>
          {noButton ? null : <Container className='no-padding' >
            {noChoose ? null : < Button small nospace rightIcon="chevron-right" outlineGradient onClick={() => null}>Choose plan</Button>}
            <Button small nospace rightIcon="chevron-right" outlineGradient onClick={() => window.open(CHECKOUT_ENDPOINT, "new")}>Buy more credits</Button>
          </Container>}
        </MyCreditsContent>
      </MyCreditsContainer >
    </>
  )
}
